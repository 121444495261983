<template>
    <section>
      <Header @open-login-modal="showLogin = true" @open-register-modal="showRegister = true" />
      <v-container>
        <TextWithImage
            :cols="[8,4]"
            image-col="2"
            title="Über Geras24"
            text="Geras24 ist der digitale Ort, wo du Antworten auf viele Fragen, die dein Leben und auch Situationen nach deinem Leben, finden wirst. Du kannst alle wichtigen Dokumente, die dein Leben betreffen, digital im Geras24-Tresor aufbewahren. Dokumente, die du für die Steuererklärung brauchst, aber auch Dokumente wie eine Verfügung, die deiner Familie bei einem schlimmen Unfall weiterhelfen werden. Geras24 kümmert sich um die Vorsorge deiner Familie nach einem schlimmen Unfall und auch nach deinem Tod. Nur wer diese schlimmsten Schicksale im Leben absichert, kann ruhiger schlafen und entspannter leben. Wir helfen dir dabei – Geras24.

<br><br>Es geht aber nicht nur um Versicherungen und Absicherungen, sondern es geht um mehr. Geras24 ist das Portal der Emotionen. Du kannst neben Dokumenten auch persönliche Botschaften und einen letzten Wunsch für deine Hinterbliebenen hinterlassen. Briefe, Notizen, Nachrichten und sogar Videos kannst du in unserer Gemeinschaft verewigen. Somit werden alle deine Wünsche auch nach deinem Tod erfüllt. Auch deine Beerdigung kannst du mit uns schon rechtzeitig planen, somit nimmst du deinen Lieben den organisatorischen Stress nach dem Tod. Denn schließlich soll es ja so ablaufen, wie du es dir wünschst.

<br><br>Doch mit deinem Tod hört Geras24 noch lange nicht auf. Deine Familie ist abgesichert und deine Freunde erhalten durch Geras24-Up Zugang in unsere Gemeinschaft. Neben einem digitalen Kondolenzbuch können deine vorher festgelegten Freunde ihre Gefühle äußern und sich miteinander austauschen. Geras24 lässt dich einfach digital weiterleben. Du hast alle digitalen Möglichkeiten, die mit dem Tod verbunden sind. Eine digitale Gedächtnistafel und eine digitale Todesanzeige sind ebenfalls ein wichtiger Bestandteil von Geras24. Entdecke die Möglichkeiten und melde dich einfach an.
"
            image="/assets/img/banner.png"
            image-width="500"
            button=""
        >
        </TextWithImage>
      </v-container>

      <Footer
          :showLogin="showLogin"
          :showRegister="showRegister"
          @close-login-modal="showLogin = false"
          @close-register-modal="showRegister = false"
      />

    </section>
</template>

<script>
import TextWithImage from "../../widgets/TextWithImage.vue";
import Header from "./Header.vue";
import Footer from "./Footer.vue";

export default {
    components:{
      TextWithImage,
      Header,
      Footer,
    }, 
    data(){
        return {
          showLogin: false,
          showRegister: false
        }
    },
}
</script>
