<template>
  <v-container fluid tag="section" class="pt-3 px-8 pb-8">

    <v-row>
      <v-col cols="12" class="pt-8 pl-3 pr-3 pb-8">
        <p class="text-h5 main-font-bold pb-7">
          <span class="pr-5">{{ $route.params.collection == 'botschaften' ? $t('collections_botschaften_title') : $t('collections_wunsch_title') }}</span>
          <span v-if="$route.params.collection == 'botschaften'"> <HelpVideo video="botschaften" title="Persönliche Botschaften"></HelpVideo></span>
        </p>
        <p class="text-subtitle-1">{{ $route.params.collection == 'botschaften' ? $t('collections_botschaften_subtitle') : $t('collections_wunsch_title') }}</p>
      </v-col>
    </v-row>

    <!-- Head Buttons -->
    <v-row class="mt-5">
      <v-tabs v-model="tab" color="primary darken-1" :fixed-tabs="!$helpers.isMobile()" @change="getMedias" v-if="$route.params.collection == 'botschaften'">
        <v-tab @click="medias = null; $router.replace({ params :{ type: 'images'}});">{{ $t('photos') }}
          <v-icon class="ml-2">mdi-folder-image</v-icon>
        </v-tab>
        <v-tab @click="medias = null; $router.replace({ params :{ type: 'documents'}});">{{ $t('documents') }}
          <v-icon class="ml-2">mdi-file-document-multiple</v-icon>
        </v-tab>
        <v-tab @click="medias = null; $router.replace({ params :{ type: 'videos'}});">{{ $t('videos') }}
          <v-icon class="ml-2">mdi-movie-open</v-icon>
        </v-tab>
      </v-tabs>
      <v-col :cols="(categories ? categories.length : false) && $route.params.collection == 'wunsch' ? '11' : '12'">
        <v-tabs color="primary darken-1" show-arrows :grow="categories.length > 4" @change="getMedias" v-if="$route.params.collection == 'wunsch' && (categories ? categories.length : false)">
          <v-tab class="white" v-for="c in categories" :key="c.id" @click="category = c.id; medias = null;">
            <v-toolbar class="elevation-0 white" flat dense color="white">
              {{ c.name }}
              <v-btn
                  small
                  icon
                  class="ml-5"
                  v-if="category == c.id"
                  @click="deleteCategoryConfirmDialog = true; deleteCategoryLoading = c.id"
                  :loading="deleteCategoryLoading == c.id"
                  :disabled="deleteCategoryLoading == c.id"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-btn small icon v-if="category == c.id" @click="newCategory = c; newCategoryDialog = true;">
                <v-icon>mdi-circle-edit-outline</v-icon>
              </v-btn>
            </v-toolbar>
          </v-tab>
        </v-tabs>
        <v-col cols="12" align="center" v-else-if="$route.params.collection == 'wunsch' && !loading">
          <v-btn large color="white" @click="newCategoryDialog = true" class="mr-2">
            <span class="primary--text"><v-icon>mdi-plus</v-icon> {{ $t('create_new_category') }}</span>
          </v-btn>
        </v-col>
      </v-col>
      <v-col v-if="(categories ? categories.length : false) && $route.params.collection == 'wunsch'" align="center">
        <v-btn fab small color="white" @click="newCategoryDialog = true">
          <v-icon color="primary">mdi-plus</v-icon>
        </v-btn>
      </v-col>

      <transition name="slide" mode="out-in">
        <v-col cols="12" v-if="$route.params.collection == 'wunsch' && (categories ? categories.length : false)">
          <v-tabs v-model="tab" color="primary darken-1" centered class="mt-5" @change="getMedias">
            <v-tab @click="medias = null; $router.replace({ params :{ type: 'images'}});">
              <v-icon>mdi-folder-image</v-icon>
            </v-tab>
            <v-tab @click="medias = null; $router.replace({ params :{ type: 'documents'}});">
              <v-icon>mdi-file-document-multiple-outline</v-icon>
            </v-tab>
            <v-tab @click="medias = null; $router.replace({ params :{ type: 'videos'}});">
              <v-icon>mdi-movie-open-outline</v-icon>
            </v-tab>
          </v-tabs>
        </v-col>
      </transition>
    </v-row>

    <!-- Medias -->
    <transition name="slide" mode="out-in">
      <v-row v-if="medias && !loading" class="mt-10" :key="1">
        <!-- Images -->
        <v-col cols="12" v-if="isType('images')">
          <div v-if="medias.length ? medias[0].mime_type.startsWith('image') : false">
            <v-col cols="12" md="6">
              <v-hover v-slot="{ hover }">
                <v-img
                    :src="medias[0].display_url"
                    aspect-ratio="1.8"
                    contain
                    class="white"
                >
                  <v-fade-transition>
                    <v-overlay
                        v-if="hover"
                        absolute
                        color="#036358"
                    >
                      <v-btn class="mr-1" x-small v-if="hover && showForUser" fab color="error" bottom left @click="deleteMedia(medias[0].id)">
                        <v-icon color="white">mdi-delete-outline</v-icon>
                      </v-btn>
                      <a v-auth-href :href="medias[0].url" v-if="hover">
                        <v-btn x-small class="mr-1" fab color="primary">
                          <v-icon color="white">mdi-cloud-download-outline</v-icon>
                        </v-btn>
                      </a>
                      <v-btn x-small class="mr-1" v-if="hover" fab color="grey" @click="openImage(medias[0])">
                        <v-icon color="white">mdi-magnify</v-icon>
                      </v-btn>
                    </v-overlay>
                  </v-fade-transition>

                  <template v-slot:placeholder>
                    <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                      <v-progress-circular
                          indeterminate
                          color="primary"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-hover>
            </v-col>
            <v-col cols="12" md="6">
              <v-row>
                <v-col
                    v-for="media in medias.slice(1, 10)" :key="media.id"
                    class="d-flex child-flex"
                    cols="4"
                >
                  <v-hover v-slot="{ hover }">
                    <v-img
                        :src="media.display_url"
                        aspect-ratio="1.7778"
                        class="white"
                    >
                      <v-fade-transition>
                        <v-overlay
                            v-if="hover"
                            absolute
                            color="#036358"
                        >
                          <v-btn class="mr-1" x-small v-if="hover && showForUser" fab color="error" bottom left @click="deleteMedia(media.id)">
                            <v-icon color="white">mdi-delete-outline</v-icon>
                          </v-btn>
                          <a v-auth-href :href="media.url" v-if="hover">
                            <v-btn x-small class="mr-1" fab color="primary">
                              <v-icon color="white">mdi-cloud-download-outline</v-icon>
                            </v-btn>
                          </a>
                          <v-btn x-small class="mr-1" v-if="hover" fab color="grey" @click="openImage(media)">
                            <v-icon color="white">mdi-magnify</v-icon>
                          </v-btn>
                        </v-overlay>
                      </v-fade-transition>


                      <template v-slot:placeholder>
                        <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                        >
                          <v-progress-circular
                              indeterminate
                              color="primary"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-hover>
                </v-col>
              </v-row>
            </v-col>
            <v-col v-if="medias.length > 10" cols="12">
              <v-row>
                <v-col
                    v-for="media in medias.slice(10)" :key="media.id"
                    class="d-flex child-flex"
                    cols="4"
                >
                  <v-hover v-slot="{ hover }">
                    <v-img
                        :src="media.display_url"
                        aspect-ratio="1.7778"
                        class="white"
                    >
                      <v-fade-transition>
                        <v-overlay
                            v-if="hover"
                            absolute
                            color="#036358"
                        >
                          <v-btn class="mr-1" x-small v-if="hover && showForUser" fab color="error" bottom left @click="deleteMedia(media.id)">
                            <v-icon color="white">mdi-delete-outline</v-icon>
                          </v-btn>
                          <a v-auth-href :href="media.url" v-if="hover">
                            <v-btn x-small class="mr-1" fab color="primary">
                              <v-icon color="white">mdi-cloud-download-outline</v-icon>
                            </v-btn>
                          </a>
                          <v-btn x-small class="mr-1" v-if="hover" fab color="grey" @click="openImage(media)">
                            <v-icon color="white">mdi-magnify</v-icon>
                          </v-btn>
                        </v-overlay>
                      </v-fade-transition>

                      <template v-slot:placeholder>
                        <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                        >
                          <v-progress-circular
                              indeterminate
                              color="primary"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-hover>
                </v-col>
              </v-row>
            </v-col>
          </div>
          <NoFilesUploaded v-else/>
        </v-col>

        <!-- Videos -->
        <v-col cols="12" v-if="isType('videos')">
          <div v-if="medias.length ? medias[0].mime_type.startsWith('video') : false">
            <v-col cols="12" md="6">
              <v-row class="pos-abs zindex-100">
                <v-btn class="ml-2 mt-2 mr-1" x-small fab color="error" bottom left @click="deleteMedia(medias[0].id)" v-if="showForUser">
                  <v-icon color="white">mdi-delete-outline</v-icon>
                </v-btn>
                <a v-auth-href :href="medias[0].url">
                  <v-btn x-small class="mt-2" fab color="primary">
                    <v-icon color="white">mdi-cloud-download-outline</v-icon>
                  </v-btn>
                </a>
              </v-row>
              <video controls class="videoInsert">
                <source :src="medias[0].display_url" :type="medias[0].mime_type">
              </video>
            </v-col>
            <v-col cols="12" md="6">
              <v-row>
                <v-col
                    v-for="media in medias.slice(1)" :key="media.id"
                    class="d-flex child-flex"
                    cols="6"
                >
                  <video controls class="videoInsert" height="200">
                    <source :src="media.display_url" :type="media.mime_type">
                  </video>
                  <v-row class="pos-abs zindex-100">
                    <v-btn class="ml-2 mt-2" x-small fab color="error" bottom left @click="deleteMedia(media.id)" v-if="showForUser">
                      <v-icon color="white">mdi-delete-outline</v-icon>
                    </v-btn>
                    <a v-auth-href :href="media.url">
                      <v-btn x-small class="mt-2" fab color="primary">
                        <v-icon color="white">mdi-cloud-download-outline</v-icon>
                      </v-btn>
                    </a>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </div>
          <NoFilesUploaded v-else/>
        </v-col>

        <!-- Audios -->
        <v-col cols="12" v-if="isType('sounds')">
          <div v-if="medias.length ? medias[0].mime_type.startsWith('audio') : false">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">#</th>
                  <th class="text-left">{{ $t('play') }}</th>
                  <th class="text-left">{{ $t('name') }}</th>
                  <th class="text-left">{{ $t('description') }}</th>
                  <th class="text-left">{{ $t('size') }}</th>
                  <th class="text-left">{{ $t('date') }}</th>
                  <th class="text-center">{{ $t('actions') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(media, i) in medias" :key="media.id">
                  <td>{{ i + 1 }}</td>
                  <th class="py-1">
                    <audio controls>
                      <source :src="media.display_url" :type="media.mime_type">
                    </audio>
                  </th>
                  <td>{{ media.name }}</td>
                  <td>{{ media.desc }}</td>
                  <td>{{ getSize(media.size) }}</td>
                  <td>{{ media.created_at }}</td>
                  <td class="text-right">
                    <v-btn class="ml-2" x-small fab color="error" bottom left @click="deleteMedia(media.id)" v-if="showForUser">
                      <v-icon color="white">mdi-delete-outline</v-icon>
                    </v-btn>
                    <a v-auth-href :href="media.url">
                      <v-btn x-small class="ml-2" fab color="primary">
                        <v-icon color="white">mdi-cloud-download-outline</v-icon>
                      </v-btn>
                    </a>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
          <NoFilesUploaded v-else/>
        </v-col>

        <!-- Documents -->
        <v-col cols="12" v-if="isType('documents')">
          <div v-if="medias.length ? medias[0].mime_type.startsWith('application') : false">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">#</th>
                  <th class="text-left">{{ $t('name') }}</th>
                  <th class="text-left">{{ $t('description') }}</th>
                  <th class="text-left">{{ $t('size') }}</th>
                  <th class="text-left">{{ $t('date') }}</th>
                  <th class="text-center">{{ $t('actions') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(media, i) in medias" :key="media.id">
                  <td>{{ i + 1 }}</td>
                  <td>{{ media.name }}</td>
                  <td>{{ media.desc }}</td>
                  <td>{{ getSize(media.size) }}</td>
                  <td>{{ media.created_at }}</td>
                  <td class="text-right">
                    <v-btn medium text fab color="error" @click="deleteMedia(media.id)" v-if="showForUser">
                      <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                    <a v-auth-href :href="media.url">
                      <v-btn medium text fab color="primary">
                        <v-icon>mdi-cloud-download-outline</v-icon>
                      </v-btn>
                    </a>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
          <NoFilesUploaded v-else/>
        </v-col>

        <!-- Add New File Button -->
        <transition name="slide" mode="out-in" v-if="showForUser">
          <v-col v-if="($route.params.collection == 'wunsch' && (categories ? categories.length : false)) || $route.params.collection != 'wunsch'" justify="center" align="center" cols="12"
                 class="mt-10 mb-16">
            <v-btn fab x-large color="primary" v-if="$route.params.collection == 'wunsch'"
                   :to="{name: $route.params.type == 'documents' ? 'newDocument' : 'newMedia', params:{ category_id: category, type: $route.params.type }}">
              <v-icon color="white">mdi-cloud-upload-outline</v-icon>
            </v-btn>
            <v-btn fab x-large color="primary" v-else :to="{name: $route.params.type == 'documents' ? 'newDocument' : 'newMedia', params: { type: $route.params.type }}">
              <v-icon color="white">mdi-cloud-upload-outline</v-icon>
            </v-btn>
            <p class="text-subtitle-1 mt-5">{{ $t('upload_new_file') }}</p>
          </v-col>
        </transition>
      </v-row>
      <v-row v-else-if="loading" class="mb-5 mt-10" :key="0">
        <v-col cols="12">
          <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </v-col>
      </v-row>
    </transition>

    <!-- Delete Media Confirm Dialog -->
    <v-dialog
        v-model="deleteConformDialog"
        persistent
        max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ $t('are_you_sure') }}
        </v-card-title>

        <v-card-text>
          {{ $t('delete_file_alert') }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
              color="error darken-1"
              text
              @click="deleteMedia(selectedMedia)"
          >
            {{ $t('yes') }}
          </v-btn>

          <v-btn
              color="primary darken-1"
              text
              @click="deleteConformDialog = false; selectedMedia = null;"
          >
            {{ $t('no') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Create New Category -->
    <v-dialog
        v-model="newCategoryDialog"
        max-width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ $t('create_new_category') }}:
        </v-card-title>

        <v-card-text align="center" justify="center">
          <v-row class="mt-5">
            <v-col cols="12" md="8">
              <v-text-field
                  outlined
                  :label="$t('category_name')"
                  maxlength="200"
                  counter
                  :disabled="newCategoryLoading"
                  :loading="newCategoryLoading"
                  v-model="newCategory.name"
                  @keyup.enter="newCategory ? (newCategory.id ? updateCategory() : addNewCategory()) : null"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-btn
                  color="white"
                  elevation="5"
                  fab
                  :disabled="!newCategory || newCategoryLoading"
                  :loading="newCategoryLoading"
                  @click="newCategory.id ? updateCategory() : addNewCategory()"
              >
                <v-icon color="primary darken-1" v-if="newCategory.id">mdi-check</v-icon>
                <v-icon color="primary darken-1" v-else>mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary darken-1"
              text
              @click="newCategoryDialog = false;"
          >
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete Category Confirm Dialog -->
    <v-dialog
        v-model="deleteCategoryConfirmDialog"
        persistent
        max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ $t('are_you_sure') }}
        </v-card-title>

        <v-card-text>
          {{ $t('delete_category_alert') }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
              color="error darken-1"
              text
              @click="deleteCategory"
          >
            {{ $t('yes') }}
          </v-btn>

          <v-btn
              color="primary darken-1"
              text
              @click="deleteCategoryConfirmDialog = false; deleteCategoryLoading = false;"
          >
            {{ $t('no') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Image Dialog -->
    <v-dialog
        v-model="imageDialog"
        transition="dialog-bottom-transition"
        max-width="60%"
        :scrollable="false"
    >
      <v-card>
        <v-card-text>
          <v-img contain :src="imageDialogContent ? imageDialogContent.display_url : ''" max-height="100%">
            <template v-slot:placeholder>
              <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
              >
                <v-progress-circular
                    indeterminate
                    color="primary"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-card-text>
        <v-card-actions class="justify-end" v-if="imageDialog">
          <v-btn class="mr-1" x-small fab color="error" bottom left @click="deleteMedia(imageDialogContent ? imageDialogContent.id : null)">
            <v-icon color="white">mdi-delete-outline</v-icon>
          </v-btn>
          <a v-auth-href :href="imageDialogContent.url">
            <v-btn x-small class="mr-1" fab color="primary">
              <v-icon color="white">mdi-cloud-download-outline</v-icon>
            </v-btn>
          </a>
          <v-btn
              fab
              x-small
              color="grey"
              @click="imageDialog = false; imageDialogContent = null;"
          >
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <NoSubscriptionDialog/>
  </v-container>
</template>

<script>

import HelpVideo from "../../../../../widgets/HelpVideo"
import NoSubscriptionDialog from "@/widgets/NoSubscriptionDialog.vue";
import NoFilesUploaded from "@/widgets/NoFilesUploaded.vue";

export default {
  components: {
    NoFilesUploaded,
    NoSubscriptionDialog,
    HelpVideo
  },
  data() {
    return {
      loading: true,
      medias: null,
      tab: 0,
      category: null,
      categories: null,
      newCategory: {
        id: null,
        name: null
      },
      newCategoryDialog: false,
      newCategoryLoading: false,
      deleteCategoryConfirmDialog: false,
      deleteCategoryLoading: false,
      fabLoader: false,
      deleteConformDialog: false,
      selectedMedia: null,
      deleteMediaLoading: false,
      defaultCategories: [
        'Wie möchte ich beerdigt werden?',
        'Was soll es zu essen geben?',
        'Wie soll der Sarg/die Urne aussehen?',
        'Freunde aus UP einladen?',
      ],
      imageDialog: false,
      imageDialogContent: null,
      showForUser: !this.$store.getters.userIsLoggedInAsTrustedUser
    }
  },
  watch: {
    $route(to, from) {
      this.getMedias()
      if (from.params.collection != to.params.collection) {
        if (this.$route.params.collection == 'wunsch') this.getCategories()
        this.loading = true
        this.medias = null
        this.type = 'images'

        this.category = null
        this.categories = null
        this.newCategory = {
          id: null,
          name: null
        }
        this.newCategoryDialog = false
        this.newCategoryLoading = false
        this.deleteCategoryConfirmDialog = false
        this.deleteCategoryLoading = false

        this.fabLoader = false
        this.deleteConformDialog = false
        this.selectedMedia = null

        this.deleteMediaLoading = false

        this.defaultCategories = [
          'Wie möchte ich beerdigt werden?',
          'Was soll es zu essen geben?',
          'Wie soll der Sarg/die Urne aussehen?',
          'Freunde aus UP einladen?',
        ]

        this.imageDialog = false
        this.imageDialogContent = null
      }
    },
    '$route.params.collection'() {
      this.$router.go({name: 'mediaIndex', params: {collection: this.$route.params.collection, type: 'images'}})
    }
  },
  mounted() {
    if (!['images', 'videos', 'documents', 'sounds'].includes(this.$route.params.type)) {
      this.$router.push({name: 'dashboard/profile/info'})
      return
    }
    this.type = this.$route.params.type
    switch (this.$route.params.type) {
      case 'images':
        this.tab = 0
        break;
      case 'documents':
        this.tab = 1
        break;
      case 'videos':
        this.tab = 2
        break;
      case 'sounds':
        this.tab = 3
        break;
    }
    this.getMedias()
    if (this.$route.params.collection == 'wunsch') this.getCategories()
  },
  methods: {
    isType(type) {
      if (this.$route.params.type === type) return true
    },
    getMedias() {
      this.loading = true
      let type = null
      if (this.$route.params.type != 'all') type = this.$route.params.type
      if (type == 'documents') {
        this.$store.dispatch('getDocuments', {
          collection: this.$route.params.collection,
          category: this.$route.params.collection == 'wunsch' ? this.category : null,
        })
            .then(resp => {
              this.medias = resp
              this.loading = false
            })
            .catch(err => {
              this.loading = false

              if (err.response.status !== 402) {
                this.$helpers.showError(err)
              }
            })
      } else {
        this.$store.dispatch('getMedias', {
          collection: this.$route.params.collection,
          type: type,
          category: this.$route.params.collection == 'wunsch' ? this.category : null
        })
            .then(resp => {
              this.medias = resp
              this.loading = false
            })
            .catch(err => {
              this.loading = false

              if (err.response.status !== 402) {
                this.$helpers.showError(err)
              }
            })
      }
    },
    deleteMedia(id) {
      if (!this.selectedMedia || !this.deleteConformDialog) {
        this.selectedMedia = id
        this.deleteConformDialog = true
      } else {
        this.deleteMediaLoading = id
        this.fabLoader = id
        this.deleteConformDialog = false
        this.$store.dispatch('deleteMedia', id)
            .then(() => {
              this.fabLoader = false
              this.deleteMediaLoading = false
              this.selectedMedia = null
              this.getMedias()
            })
            .catch(err => {
              this.deleteMediaLoading = false
              this.fabLoader = false

              if (err.response.status !== 402) {
                this.$helpers.showError(err)
              }
            })
      }
    },
    getSize(size) {
      let s = Math.floor(size / 1024)
      let format = 'KB'
      if (s >= 1024) {
        s = Math.floor(s / 1024)
        format = 'MB'
      }
      if (s >= 1024) {
        s = Math.floor(s / 1024)
        format = 'GB'
      }
      return `${s} ${format}`
    },
    getCategories() {
      this.loading = true
      this.$store.dispatch('getCategories', 'medias')
          .then(resp => {
            this.categories = resp
            if (resp.length && !this.category) this.category = resp[0].id

            if (!resp.length) {
              for (let index = 0; index < this.defaultCategories.length; index++) {
                const cat = this.defaultCategories[index];
                this.$store.dispatch('storeCategories', {
                  type: 'medias',
                  name: cat
                })
                if (index == this.defaultCategories.length - 1) this.getCategories()
              }
            } else {
              this.loading = false
            }
          })
    },
    addNewCategory() {
      this.newCategoryLoading = true
      this.$store.dispatch('storeCategories', {
        type: 'medias',
        name: this.newCategory.name
      })
          .then(() => {
            this.newCategory = {
              id: null,
              name: null
            }
            this.newCategoryLoading = false
            this.newCategoryDialog = false
            this.getCategories()
          })
          .catch(err => {
            this.newCategoryLoading = false

            if (err.response.status !== 402) {
              this.$helpers.showError(err)
            }
          })
    },
    updateCategory() {
      this.newCategoryLoading = true
      this.$store.dispatch('updateCategories', this.newCategory)
          .then(() => {
            this.newCategory = {
              id: null,
              name: null
            }
            this.newCategoryLoading = false
            this.newCategoryDialog = false
            this.getCategories()
          })
          .catch(err => {
            this.newCategoryLoading = false

            if (err.response.status !== 402) {
              this.$helpers.showError(err)
            }
          })
    },
    deleteCategory() {
      this.$store.dispatch('deleteCategories', {
        id: this.category,
        type: 'wunch'
      })
          .then(() => {
            this.category = null
            this.deleteCategoryLoading = false
            this.deleteCategoryConfirmDialog = false
            this.getCategories()
          })
          .catch(err => {
            this.deleteCategoryLoading = false
            this.deleteCategoryConfirmDialog = false

            if (err.response.status !== 402) {
              this.$helpers.showError(err)
            }
          })
    },
    openImage(media) {
      this.imageDialogContent = media
      this.imageDialog = true
    },
  }
}
</script>
