<template>
  <v-container
      fluid
      tag="section"
      class="pt-3 px-8 pb-8"
  >
    <v-row>
      <v-col cols="9" class="pt-8 pl-3 pr-3 pb-8">
        <p class="text-h5 main-font-bold">{{ $t('upload_new_file') }}</p>
      </v-col>
      <v-col cols="3" align="right" justify="end" class="pt-8 pl-3 pr-3 pb-8">
        <router-link :to="{ name: 'tresorIndex' }">
          <v-btn color="white" fab>
            <span class="primary--text"><v-icon>mdi-arrow-right</v-icon></span>
          </v-btn>
        </router-link>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <v-form v-if="!uploading">
          <v-row>
            <v-col cols="12" md="6">
              <v-file-input
                  v-model="file"
                  color="primary darken-1"
                  :label="$t('new_file')"
                  :placeholder="$t('select_file')"
                  prepend-icon="mdi-paperclip"
                  outlined
                  :disabled="uploading"
                  :show-size="1024"
                  :error="!file && fileError"
              >
              </v-file-input>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                  outlined
                  :label="$t('file_desc')"
                  prepend-icon="mdi-card-text-outline"
                  maxlength="200"
                  counter
                  :disabled="uploading"
                  v-model="desc"
                  :error="!desc && descError"
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                justify="right"
                align="right"
            >
              <v-btn
                  large
                  :loading="uploading"
                  :disabled="uploading"
                  color="primary"
                  class="ma-2 white--text px-10"
                  :style="{'width': $vuetify.breakpoint.smAndDown ? '100%' : ''}"
                  @click="encryptProcess"
              >
                {{ $t('upload') }}
                <v-icon
                    right
                    dark
                >
                  mdi-cloud-upload-outline
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <v-progress-linear v-else indeterminate color="primary"></v-progress-linear>
      </v-col>
    </v-row>

    <!-- init keys Dialog -->
    <v-dialog
        v-model="initKeys"
        persistent
        max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ $t('no_keys') }}
        </v-card-title>

        <v-card-text>
          {{ $t('no_keys_subtitle') }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
              color="error darken-1"
              text
              @click="$router.push({ name: 'profileInfo' })"
          >
            {{ $t('later') }}
          </v-btn>

          <v-btn
              color="primary darken-1"
              text
              @click="$router.push({ name: 'keys' })"
          >
            {{ $t('create_now') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Otp Dialog -->
    <v-dialog
        v-model="otpDialog"
        persistent
        max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ $t('otp_title') }}
        </v-card-title>

        <v-card-text align="center" class="mt-5">
          <v-text-field
              class="pa-0"
              :placeholder="$t('otp_code')"
              v-model="otp"
              :loading="otpLoading"
              :disabled="otpLoading"
              outlined
          ></v-text-field>
        </v-card-text>

        <v-card-text align="center" justify="center">
          <v-btn fab color="primary" class="ma-2" @click="loginOtp" :disabled="otpLoading || !otp">
            <v-icon>mdi-lock-outline</v-icon>
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Vue from "vue";
import {Decrypt, Encrypt} from '@/plugins/utils.js'

export default {
  data() {
    return {
      name: null,
      nameError: false,
      pemError: false,
      desc: null,
      descError: false,
      fileError: false,
      file: null,
      uploading: false,
      prvKeyFile: null,

      fileEncoded: null,
      encryptedFile: null,
      decryptedFile: null,
      encryptedKeys: null,
      decryptedKeys: null,
      keys: null,
      prvKey: null,
      prvKeyText: null,
      pubKeyText: null,
      pubKey: this.$store.getters.user.user.public_key,
      generateKeysDialg: false,

      key: null,
      encryptedKey: null,
      iv: null,
      encryptedIv: null,

      pubKeyPEM: null,

      initKeys: false,
      otpLoading: false,
      otpDialog: false,
      otp: null
    };
  },
  mounted() {
    if (!this.$store.getters.tresorToken) this.otpDialog = true
    else this.getMe()
  },
  methods: {
    getMe() {
      this.uploading = true
      this.$store.dispatch('getMe')
          .then(resp => {
            this.pubKeyPEM = resp.public_key
            if (resp.public_key) this.getPrvKey()
            else {
              this.initKeys = true
              this.initSecurity()
            }
          })
    },
    uploadFile() {
      if (!this.file) {
        this.fileError = true
        setTimeout(() => {
          this.fileError = false
        }, 2000);
        return
      }
      if (!this.desc) {
        this.descError = true
        setTimeout(() => {
          this.descError = false
        }, 2000);
        return
      }
      this.uploading = true
      var formData = new FormData();
      // formData.append('file', new File([new Blob([this.encryptedFile])], this.file.name));
      formData.append('file', this.file, this.file.name);
      formData.append('key', this.encryptedKeys);
      formData.append('desc', this.desc);

      this.$store.dispatch('uploadTresorFile', formData)
          .then(() => {
            Vue.$toast.success(this.$t('file_upload_success'))
            this.$router.push({name: 'tresorIndex'})
          })
          .catch(err => {
            this.uploading = false
            this.$helpers.showError(err)
          })
    },
    encryptProcess() {
      if (!this.file) {
        this.fileError = true
        return
      }
      if (!this.desc) {
        this.descError = true
        return
      }
      if (!this.prvKeyText) {
        this.pemError = true
        return
      }

      this.uploading = true
      this.uploadFile()
      // this.encodingFile()
    },
    encryptFile() {
      this.key = this.$helpers.generatePassword(64)
      this.iv = this.$helpers.generatePassword(64)
      this.keys = JSON.stringify({
        key: this.key,
        iv: this.iv
      })

      this.encryptedFile = Encrypt(this.fileEncoded, this.key, this.iv)
      this.decryptFile()
      setTimeout(() => {
        this.encryptKeys()
      }, 2000);
    },
    decryptFile() {
      setTimeout(() => {
        if (this.encryptedFile) this.decryptedFile = Decrypt(this.encryptedFile, this.key, this.iv)
        else this.decryptFile()
      }, 2000);
    },
    encodingFile() {
      var reader = new FileReader()
      reader.readAsDataURL(this.file)
      reader.onload = () => {
        this.fileEncoded = reader.result;
        this.encryptFile()
      };
    },
    encryptKeys() {
      this.encryptedKey = this.encryptedData(this.pubKeyPEM, this.key);
      this.encryptedIv = this.encryptedData(this.pubKeyPEM, this.iv);
      this.encryptedKeys = JSON.stringify({
        key: this.encryptedKey,
        iv: this.encryptedIv
      })
      this.uploadFile()
      this.decryptKeys()
    },
    decryptKeys() {
      let key = this.decryptData(this.prvKeyText, this.encryptedKey);
      let iv = this.decryptData(this.prvKeyText, this.encryptedIv);
      this.decryptedKeys = JSON.stringify({
        key: key,
        iv: iv
      })
    },
    //  encryption
    encryptedData(publicKey, data) {
      // New JSEncrypt object
      let encryptor = new this.$rsa();
      // Setting public key
      encryptor.setPublicKey(publicKey);
      // Encrypted data
      return encryptor.encrypt(data);
    },
    // Decrypt
    decryptData(privateKey, data) {
      // New JSEncrypt object
      let decrypt = new this.$rsa();
      // Set private key
      decrypt.setPrivateKey(privateKey);
      // Declassified data
      return decrypt.decrypt(data);
    },
    prvKeyValidation(file) {
      if (this.prvKeyFile) {
        var fileReader = new FileReader();
        var that = this
        fileReader.onload = function (fileLoadedEvent) {
          var textFromFileLoaded = fileLoadedEvent.target.result;
          let prvKeyText = textFromFileLoaded

          // Check Validation
          let result = that.$helpers.prvKeyValidator(that.pubKeyPEM, prvKeyText)
          if (!result) {
            that.prvKeyFile = null
            that.pemError = true
            that.$helpers.showMessage('error', that.$t('prv_pem_invalid'))
          }
        };

        fileReader.readAsText(that.prvKeyFile, "UTF-8");
      }
    },
    initSecurity() {
      this.generateKey()
          .then(resp => {
            this.$store.dispatch('setPubKey', {
              public_key: this.pubKeyPEM
            })
                .then(resp => {
                  this.$store.dispatch('setPrvKey', {
                    private_key: this.prvKeyPEM
                  })
                      .then(resp => {
                        this.initKeys = false

                      })
                })
          })
    },
    getPrvKey() {
      this.$store.dispatch('getKeys')
          .then(resp => {
            this.prvKeyText = resp.private_key
            this.uploading = false
          })
          .catch(err => {
            if (err.response.status === 402) this.paymentRequireDialog = true
            else this.$helpers.showError(err)
          })
    },
    loginOtp() {
      if (!this.otp) {
        Vue.$toast.error(this.$t('enter_otp'))
        return
      }

      this.otpLoading = true
      this.$store.dispatch('tresorOtp', {
        otp: this.otp,
      })
          .then(resp => {
            this.initKeys = true
            this.otpDialog = false
            this.getMe()
          })
          .catch(err => {
            this.$helpers.showError(err)
            this.otpLoading = false
          })
    },

  }
};
</script>
