<template>
  <v-container fluid tag="section" class="pt-3 px-8 pb-8">
    <!-- Head Buttons -->
    <v-row>
      <v-col cols="10" class="pt-8 pl-3 pr-3 pb-8">
        <p class="text-h5 main-font-bold pb-7"><span class="pr-5">{{ $t('tresor') }}</span>
          <HelpVideo video="tresor" title="Der Tresor"></HelpVideo>
        </p>
        <p class="text-subtitle-1">{{ $t('tresor_subtitle') }}</p>
      </v-col>
    </v-row>

    <!-- Medias -->
    <transition name="slide" mode="out-in">
      <v-row v-if="!loading" :key="1">
        <v-col cols="12">
          <div v-if="files ? files.length : false">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">#</th>
                  <th class="text-left">{{ $t('name') }}</th>
                  <th class="text-left">{{ $t('description') }}</th>
                  <th class="text-left">{{ $t('size') }}</th>
                  <th class="text-left">{{ $t('date') }}</th>
                  <th class="text-center">{{ $t('actions') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(file, i) in files" :key="file.id">
                  <td>{{ i + 1 }}</td>
                  <td>{{ file.name }}</td>
                  <td>{{ file.desc }}</td>
                  <td>{{ getSize(file.size) }}</td>
                  <td>{{ file.created_at }}</td>
                  <td class="text-right">
                    <v-btn medium text fab color="error" @click="lastAction = { action: 'delete', payload: file.id }; deleteTresorFile(file.id)" :loading="deleteTresorFileLoading == file.id"
                           :disabled="deleteTresorFileLoading == file.id" v-if="showForUser">
                      <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                    <a :href="file.url" v-auth-href>
                      <v-btn medium text fab color="primary">
                        <v-icon>mdi-cloud-download-outline</v-icon>
                      </v-btn>
                    </a>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
          <NoFilesUploaded v-else-if="!otpDialog"/>
        </v-col>
        <!-- Add New File Button -->
        <v-col justify="center" align="center" cols="12" class="mt-10 mb-16" v-if="showForUser">
          <v-btn fab x-large color="primary" :to="{name: 'newTresor'}">
            <v-icon color="white">mdi-cloud-upload-outline</v-icon>
          </v-btn>
          <p class="text-subtitle-1 mt-5">{{ $t('upload_new_file') }}</p>
        </v-col>
      </v-row>
      <v-row v-else class="mb-5 mt-5" :key="0">
        <v-col cols="12">
          <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </v-col>
      </v-row>
    </transition>

    <!-- Delete File Confirm Dialog -->
    <v-dialog
        v-model="deleteConformDialog"
        persistent
        max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ $t('are_you_sure') }}
        </v-card-title>

        <v-card-text>
          {{ $t('delete_file_alert') }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
              color="error darken-1"
              text
              @click="deleteTresorFile(selectedMedia)"
          >
            {{ $t('yes') }}
          </v-btn>

          <v-btn
              color="primary darken-1"
              text
              @click="deleteConformDialog = false; selectedMedia = null;"
          >
            {{ $t('no') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Init Keys Dialog -->
    <v-dialog
        v-model="initKeys"
        persistent
        max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ $t('init_security_keys') }}
        </v-card-title>

        <v-card-text align="center" class="mt-5">
          <v-progress-linear indeterminate color="primary"></v-progress-linear>
          <div class="text-body-1 mt-2">{{ $t('please_wait') }}</div>
        </v-card-text>

      </v-card>
    </v-dialog>

    <!-- Otp Dialog -->
    <v-dialog
        v-model="otpDialog"
        persistent
        max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ $t('otp_title') }}
        </v-card-title>

        <v-card-text align="center" class="mt-5">
          <v-text-field
              class="pa-0"
              :placeholder="$t('otp_code')"
              v-model="otp"
              :loading="otpLoading"
              :disabled="otpLoading"
              outlined
          ></v-text-field>
        </v-card-text>

        <v-card-text align="center" justify="center">
          <v-row class="align-self-center ml-0 mb-0 mr-0">
            <router-link :to="{ name: 'profileInfo' }" class="align-self-center">
              <span class="error--text"><v-icon class="mr-2" color="error">mdi-arrow-left</v-icon> {{ $t('cancel') }}</span>
            </router-link>
            <v-spacer></v-spacer>
            <v-btn fab color="primary" class="mx-2" @click="loginOtp" :disabled="otpLoading || !otp">
              <v-icon>mdi-lock-outline</v-icon>
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <NoSubscriptionDialog/>
  </v-container>
</template>

<script>
import moment from 'moment'
import Vue from 'vue'
import HelpVideo from "../../../../widgets/HelpVideo"
import NoSubscriptionDialog from "@/widgets/NoSubscriptionDialog.vue";
import NoFilesUploaded from "@/widgets/NoFilesUploaded.vue";

export default {
  components: {
    NoFilesUploaded,
    NoSubscriptionDialog,
    moment,
    HelpVideo
  },
  data() {
    return {
      loading: true,
      files: null,
      deleteConformDialog: false,
      initKeys: false,
      selectedMedia: null,
      downloadTresorFileLoading: false,
      deleteTresorFileLoading: false,
      pubKeyPEM: null,
      lastAction: null,
      prvKeyPEM: null,
      otp: null,
      otpDialog: false,
      otpLoading: false,
      showForUser: !this.$store.getters.userIsLoggedInAsTrustedUser
    }
  },
  mounted() {
    this.checkTresorFeature()
  },
  methods: {
    checkTresorFeature() {
      this.loading = true
      return this.$store.dispatch('checkTresorFeature')
          .then(() => {
            this.loading = false
            if (this.showForUser && !this.$store.getters.tresorToken) this.otpDialog = true
            else this.getMe()
          })
          .catch(() => {
            this.loading = false
          })
    },
    getMe() {
      this.loading = true
      this.$store.dispatch('getMe')
          .then(resp => {
              this.getFiles()
          })
    },
    getFiles() {
      this.loading = true
      this.$store.dispatch('getFiles')
          .then(resp => {
            this.files = resp
            this.loading = false
          })
          .catch(err => {
            this.loading = false

            if (err.response.status !== 402) {
              this.$helpers.showError(err)
            }
          })
    },
    deleteTresorFile(id) {
      if (!this.selectedMedia) {
        this.selectedMedia = id
        this.deleteConformDialog = true
      } else {
        this.deleteTresorFileLoading = id
        this.fabLoader = id
        this.deleteConformDialog = false
        this.$store.dispatch('deleteTresorFile', {id: id})
            .then(() => {
              this.fabLoader = false
              this.deleteTresorFileLoading = false
              this.selectedMedia = null
              this.getFiles()
            })
            .catch(err => {
              this.deleteTresorFileLoading = false
              this.fabLoader = false

              if (err.response.status !== 402) {
                this.$helpers.showError(err)
              }
            })
      }
    },
    getSize(size) {
      let s = Math.floor(size / 1024)
      let format = 'KB'
      if (s >= 1024) {
        s = Math.floor(s / 1024)
        format = 'MB'
      }
      if (s >= 1024) {
        s = Math.floor(s / 1024)
        format = 'GB'
      }
      return `${s} ${format}`
    },
    loginOtp() {
      if (!this.otp) {
        Vue.$toast.error(this.$t('enter_otp'))
        return
      }

      this.otpLoading = true
      this.$store.dispatch('tresorOtp', {
        otp: this.otp,
      })
          .then(() => {
            this.otpDialog = false
            this.getMe()
          })
          .catch(err => {
            this.$helpers.showError(err)
            this.otpLoading = false

            if (err.response.status === 412) {
              setTimeout(() => {
                this.$router.push({
                  name: 'profilePassword',
                });
              }, 1000)
            }
          })
    },
  }
}
</script>
