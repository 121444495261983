// Middlewares
import AuthMiddleware from "./middleware/auth";
import GuestMiddleware from "./middleware/guest";

// Components
import Home from '../../components/public/Index.vue'
import Impressum from '../../components/public/Impressum.vue'
import Datenschutz from '../../components/public/Datenschutz.vue'

import Login from '../../components/public/auth/Login.vue'
import Register from '../../components/public/auth/Register.vue'
import OTP from '../../components/public/auth/OTP.vue'
import Reset from '../../components/public/auth/Reset.vue'

import DashboardIndex from '../../components/public/dashboard/Index.vue'
import DashboardState from '../../components/public/dashboard/dashboard/Index.vue'

import Collections from '../../components/public/dashboard/collections/Index.vue'

import Media from '../../components/public/dashboard/collections/medias/Index.vue'
import MediaIndex from '../../components/public/dashboard/collections/medias/List.vue'
import NewMedia from '../../components/public/dashboard/collections/medias/New.vue'

import Document from '../../components/public/dashboard/collections/documents/Index.vue'
import DocumentIndex from '../../components/public/dashboard/collections/documents/List.vue'
import NewDocument from '../../components/public/dashboard/collections/documents/New.vue'

import Tresor from '../../components/public/dashboard/tresor/Index.vue'
import TresorIndex from '../../components/public/dashboard/tresor/List.vue'
import NewTresor from '../../components/public/dashboard/tresor/New.vue'

import Personaldocs from '../../components/public/dashboard/personaldocs/Index.vue'
import PersonaldocsList from '../../components/public/dashboard/personaldocs/List.vue'
import NewPersonaldocs from '../../components/public/dashboard/personaldocs/New.vue'

import Advisor from '../../components/public/dashboard/advisor/Index.vue'
import AdvisorList from '../../components/public/dashboard/advisor/List.vue'
import NewAdvisor from '../../components/public/dashboard/advisor/New.vue'

import Benefitpartners from '../../components/public/dashboard/benefitpartners/Index.vue'
import BenefitpartnersList from '../../components/public/dashboard/benefitpartners/List.vue'

import Lastwish from '../../components/public/dashboard/lastwish/Index.vue'
import LastwishForm from '../../components/public/dashboard/lastwish/Form.vue'

import Documenttemps from '../../components/public/dashboard/documenttemps/Index.vue'
import DocumenttempsList from '../../components/public/dashboard/documenttemps/List.vue'

import Support from '../../components/public/dashboard/support/Index.vue'
import SupportIndex from '../../components/public/dashboard/support/List.vue'
import NewSupport from '../../components/public/dashboard/support/New.vue'
import ShowSupport from '../../components/public/dashboard/support/Show.vue'

import Profile from '../../components/public/dashboard/profile/Index.vue'
import ProfileInfo from '../../components/public/dashboard/profile/Info.vue'
import ProfilePassword from '../../components/public/dashboard/profile/Password.vue'
import ProfileAccount from '../../components/public/dashboard/profile/Account.vue'
import ProfilePlans from '../../components/public/dashboard/profile/Plans.vue'
import ProfileNotifications from '../../components/public/dashboard/profile/Notifications.vue'
import ProfilePayVerify from '../../components/public/dashboard/profile/PayVerify.vue'
import ProfileSubscriptions from '../../components/public/dashboard/profile/Subscriptions.vue'

import TrustedUsers from '../../components/public/dashboard/trustedUsers/Index.vue'
import TrustedUsersList from '../../components/public/dashboard/trustedUsers/trusted/List.vue'
import EntrustedUsersList from '../../components/public/dashboard/trustedUsers/entrusted/List.vue'

import Checklist from '../../components/public/dashboard/checklist/Index.vue'

import Product from '../../components/public/Product.vue'
import About from '../../components/public/About.vue'
import SellerTerms from "../../components/public/SellerTerms";
import ContactUs from "../../components/public/ContactUs";
import Faq from "../../components/public/Faq";
import NotfallBox from "../../components/public/NotfallBox";

import lang from '../../plugins/i18n.js'

export default [
    // Auth
    {
        path: '/auth',
        component: Login,
        name: 'login',
        params: true,
        meta: {middleware: [GuestMiddleware]},
        children: [
            {
                path: 'register',
                component: Register,
                name: 'register',
                params: true,
            },
            {
                path: 'otp',
                component: OTP,
                name: 'otp',
                params: true,
                meta: {
                    authOnly: true
                },
            },
            {
                path: 'reset/:token?',
                component: Reset,
                name: 'resetPassword',
            },
            {
                path: ':provider',
                component: Login,
                name: 'providerCallback',
                params: true,
            },
        ]
    },
    // Home
    {
        path: '/',
        component: Home,
        name: 'home',
        meta: {
            title: lang.t('meta_tags')['home'].title,
            description: lang.t('meta_tags')['home'].description
        },
        children: [
            // Dashboard
            {
                path: 'dashboard',
                component: DashboardIndex,
                redirect: {name: 'dashboardStats'},
                name: 'dashboard',
                meta: {
                    middleware: [AuthMiddleware],
                    title: lang.t('meta_tags')['dashboard'].title,
                },
                children: [
                    // Dashboard
                    {
                        path: 'dashboard',
                        component: DashboardState,
                        name: 'dashboardStats',
                        meta: {
                            title: lang.t('meta_tags')['dashboard'].title,
                            authOnly: true
                        },
                    },
                    // Support
                    {
                        path: 'support',
                        component: Support,
                        redirect: {name: 'supportIndex'},
                        name: 'support',
                        meta: {
                            authOnly: true
                        },
                        children: [
                            {
                                path: '/',
                                component: SupportIndex,
                                name: 'supportIndex',
                                meta: {
                                    title: lang.t('meta_tags')['dashboard'].support.title,
                                    authOnly: true
                                },
                            },
                            {
                                path: 'new',
                                component: NewSupport,
                                name: 'newSupport',
                                meta: {
                                    title: lang.t('meta_tags')['dashboard'].support.title,
                                    authOnly: true
                                },
                            },
                            {
                                path: 'show/:id',
                                component: ShowSupport,
                                name: 'showSupport',
                                meta: {
                                    title: lang.t('meta_tags')['dashboard'].support.title,
                                    authOnly: true
                                },
                            }
                        ]
                    },
                    // Checklist
                    {
                        path: 'checklist',
                        component: Checklist,
                        name: 'checklist',
                        meta: {
                            title: lang.t('meta_tags')['dashboard'].checklist.title,
                            authOnly: true
                        },
                    },
                    // Tresor
                    {
                        path: 'tresor',
                        component: Tresor,
                        redirect: {name: 'tresorIndex'},
                        name: 'tresor',
                        meta: {
                            authOnly: true
                        },
                        children: [
                            {
                                path: '/',
                                component: TresorIndex,
                                name: 'tresorIndex',
                                meta: {
                                    title: lang.t('meta_tags')['dashboard'].tresor.title,
                                    authOnly: true
                                },
                            },
                            {
                                path: 'new',
                                component: NewTresor,
                                name: 'newTresor',
                                meta: {
                                    title: lang.t('meta_tags')['dashboard'].tresor.title,
                                    authOnly: true
                                },
                            },
                        ]
                    },
                    // Profile
                    {
                        path: 'profile',
                        component: Profile,
                        redirect: {name: 'profileInfo'},
                        name: 'profile',
                        meta: {
                            authOnly: true
                        },
                        children: [
                            {
                                path: 'info',
                                component: ProfileInfo,
                                name: 'profileInfo',
                                meta: {
                                    title: lang.t('meta_tags')['dashboard'].profile.title,
                                    authOnly: true,
                                    index: 0
                                },
                            },
                            {
                                path: 'account',
                                component: ProfileAccount,
                                redirect: {name: 'profilePlans'},
                                name: 'profileAccount',
                                meta: {
                                    authOnly: true,
                                    index: 1
                                },
                                children: [
                                    // Plans
                                    {
                                        path: 'plans',
                                        component: ProfilePlans,
                                        name: 'profilePlans',
                                        meta: {
                                            title: lang.t('meta_tags')['dashboard'].profile.title,
                                            authOnly: true,
                                            index: 1
                                        },
                                    },
                                    // Payment Verify
                                    {
                                        path: 'pay/:id/:status',
                                        component: ProfilePayVerify,
                                        name: 'payVerify',
                                        meta: {
                                            title: lang.t('meta_tags')['dashboard'].profile.title,
                                            authOnly: true,
                                            index: 1
                                        },
                                    },
                                ]
                            },
                            {
                                path: 'password',
                                component: ProfilePassword,
                                name: 'profilePassword',
                                meta: {
                                    title: lang.t('meta_tags')['dashboard'].profile.title,
                                    authOnly: true,
                                    index: 2
                                },
                            },
                            {
                                path: 'notifications',
                                component: ProfileNotifications,
                                name: 'profileNotifications',
                                meta: {
                                    title: lang.t('meta_tags')['dashboard'].profile.title,
                                    authOnly: true,
                                    index: 4
                                },
                            },
                            {
                                path: 'subscriptions',
                                component: ProfileSubscriptions,
                                name: 'profileSubscriptions',
                                meta: {
                                    title: lang.t('meta_tags')['dashboard'].profile.title,
                                    authOnly: true,
                                    index: 5
                                },
                            }
                        ]
                    },
                    // Trusted Users
                    {
                        path: 'trusted-users',
                        component: TrustedUsers,
                        redirect: {name: 'trustedUsersList'},
                        name: 'trustedUsers',
                        meta: {
                            authOnly: true
                        },
                        children: [
                            {
                                path: 'trusted',
                                component: TrustedUsersList,
                                name: 'trustedUsersList',
                                meta: {
                                    title: lang.t('meta_tags')['dashboard'].trusted_users.title,
                                    authOnly: true,
                                    index: 0
                                },
                            },
                            {
                                path: 'entrusted',
                                component: EntrustedUsersList,
                                name: 'entrustedUsersList',
                                meta: {
                                    title: lang.t('meta_tags')['dashboard'].trusted_users.title,
                                    authOnly: true,
                                    index: 1
                                },
                            }
                        ]
                    },
                    // Collections
                    {
                        path: ':collection',
                        component: Collections,
                        redirect: {name: 'mediaIndex'},
                        name: 'collections',
                        meta: {
                            authOnly: true
                        },
                        children: [
                            // Medias
                            {
                                path: 'medias',
                                component: Media,
                                redirect: {name: 'mediaIndex'},
                                name: 'medias',
                                meta: {
                                    title: lang.t('meta_tags')['dashboard'].personal_messages.title,
                                    authOnly: true
                                },
                                children: [{
                                    path: 'list/:type',
                                    component: MediaIndex,
                                    name: 'mediaIndex',
                                    meta: {
                                        title: lang.t('meta_tags')['dashboard'].personal_messages.title,
                                        authOnly: true
                                    },
                                },
                                    {
                                        path: 'new/:type/:category_id?',
                                        component: NewMedia,
                                        name: 'newMedia',
                                        meta: {
                                            title: lang.t('meta_tags')['dashboard'].personal_messages.title,
                                            authOnly: true
                                        },
                                    },
                                ]
                            },
                            // Documents
                            {
                                path: 'documents',
                                component: Document,
                                redirect: {name: 'documentIndex'},
                                name: 'documents',
                                meta: {
                                    title: lang.t('meta_tags')['dashboard'].personal_messages.title,
                                    authOnly: true
                                },
                                children: [
                                    {
                                        path: '/',
                                        component: DocumentIndex,
                                        name: 'documentIndex',
                                        meta: {
                                            title: lang.t('meta_tags')['dashboard'].personal_messages.title,
                                            authOnly: true
                                        },
                                    },
                                    {
                                        path: 'new/:type/:category_id?',
                                        component: NewDocument,
                                        name: 'newDocument',
                                        meta: {
                                            title: lang.t('meta_tags')['dashboard'].personal_messages.title,
                                            authOnly: true
                                        },
                                    },
                                ]
                            },
                        ]
                    },
                    // Personal Docs
                    {
                        path: 'personaldocs',
                        component: Personaldocs,
                        redirect: {name: 'personaldocsList'},
                        name: 'personaldocsIndex',
                        meta: {
                            authOnly: true
                        },
                        params: true,
                        children: [
                            {
                                path: 'list',
                                component: PersonaldocsList,
                                name: 'personaldocsList',
                                meta: {
                                    title: lang.t('meta_tags')['dashboard'].personal_documents.title,
                                    authOnly: true,
                                },
                            },
                            {
                                path: 'new/:category_id?',
                                component: NewPersonaldocs,
                                name: 'newPersonaldocs',
                                meta: {
                                    title: lang.t('meta_tags')['dashboard'].personal_documents.title,
                                    authOnly: true,
                                },
                            }
                        ]
                    },
                    // Advisor
                    {
                        path: 'advisor',
                        component: Advisor,
                        redirect: {name: 'advisorList'},
                        name: 'advisorIndex',
                        meta: {
                            authOnly: true
                        },
                        params: true,
                        children: [
                            {
                                path: 'list',
                                component: AdvisorList,
                                name: 'advisorList',
                                meta: {
                                    title: lang.t('meta_tags')['dashboard'].advisor.title,
                                    authOnly: true,
                                },
                            },
                            {
                                path: 'new/:category_id?',
                                component: NewAdvisor,
                                name: 'newAdvisor',
                                meta: {
                                    title: lang.t('meta_tags')['dashboard'].advisor.title,
                                    authOnly: true,
                                },
                            }
                        ]
                    },
                    // Benefit Partners
                    {
                        path: 'benefit-partners',
                        component: Benefitpartners,
                        redirect: {name: 'benefitpartnersList'},
                        name: 'benefitpartnersIndex',
                        meta: {
                            authOnly: true
                        },
                        params: true,
                        children: [
                            {
                                path: 'list',
                                component: BenefitpartnersList,
                                name: 'benefitpartnersList',
                                meta: {
                                    title: lang.t('meta_tags')['dashboard'].benefit_partners.title,
                                    authOnly: true,
                                },
                            },
                        ]
                    },
                    // Last Wish
                    {
                        path: 'lastwish',
                        component: Lastwish,
                        redirect: {name: 'lastwishForm'},
                        name: 'lastwishIndex',
                        meta: {
                            authOnly: true
                        },
                        params: true,
                        children: [
                            {
                                path: 'form',
                                component: LastwishForm,
                                name: 'lastwishForm',
                                meta: {
                                    title: lang.t('meta_tags')['dashboard'].last_wish.title,
                                    authOnly: true,
                                },
                            }
                        ]
                    },
                    // Document tamplates
                    {
                        path: 'documenttemps',
                        component: Documenttemps,
                        redirect: {name: 'documenttempsList'},
                        name: 'documenttempsIndex',
                        meta: {
                            authOnly: true
                        },
                        children: [{
                            path: 'list',
                            component: DocumenttempsList,
                            name: 'documenttempsList',
                            meta: {
                                title: lang.t('meta_tags')['dashboard'].document_templates.title,
                                authOnly: true,
                            },
                        }
                        ]
                    },
                ]
            },
            // Email Verify
            {
                path: '/email/verify',
                component: Login,
                name: 'authVerify',
                props: true,
            }
        ]
    },
    // Products
    {
        path: '/produkte/:product',
        component: Product,
        name: 'product',
        params: true,
        beforeEnter: (to, from, next) => {
            const validProducts = ['checkliste', 'persoenliche-botschaft', 'tresor', 'der-letzte-wunsch'];

            if (validProducts.includes(to.params.product)) {
                next();
            } else {
                next('/');
            }
        }
    },
    // Impressum
    {
        path: '/impressum',
        component: Impressum,
        name: 'impressum',
        meta: {
            title: lang.t('meta_tags')['impressum'].title,
            description: lang.t('meta_tags')['impressum'].description
        }
    },
    // Datenschutz
    {
        path: '/datenschutz',
        component: Datenschutz,
        name: 'datenschutz',
        meta: {
            title: lang.t('meta_tags')['datenschutz'].title,
            description: lang.t('meta_tags')['datenschutz'].description
        }
    },
    // Seller terms
    {
        path: '/allgemeine-verkaufsbedingungen',
        component: SellerTerms,
        name: 'sellerTerms',
        meta: {
            title: lang.t('meta_tags')['seller_terms'].title,
            description: lang.t('meta_tags')['seller_terms'].description
        }
    },
    // About
    {
        path: '/ueber-geras24',
        component: About,
        name: 'about',
        meta: {
            title: lang.t('meta_tags')['about'].title,
            description: lang.t('meta_tags')['about'].description
        }
    },
    // Contact
    {
        path: '/kontakt',
        component: ContactUs,
        name: 'contactUs',
        meta: {
            title: lang.t('meta_tags')['contact_us'].title,
            description: lang.t('meta_tags')['contact_us'].description
        }
    },
    // Help
    {
        path: '/hilfe',
        component: Faq,
        name: 'faq',
        meta: {
            title: lang.t('meta_tags')['faq'].title,
            description: lang.t('meta_tags')['faq'].description
        }
    },
    // Notfallbox
    {
        path: '/notfallbox',
        component: NotfallBox,
        name: 'notfallBox',
        meta: {
            title: lang.t('meta_tags')['notfallbox'].title,
            description: lang.t('meta_tags')['notfallbox'].description
        }
    },
    {
        path: '*',
        redirect: '/',
        meta: {
            title: lang.t('meta_tags')['home'].title,
            description: lang.t('meta_tags')['home'].description
        }
    }
];
