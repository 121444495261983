<template>
  <v-container fluid tag="section" class="pt-3 px-8 pb-8">
    <!-- Head Buttons -->
    <v-row>
      <v-col>
        <v-row>
          <v-col cols="12" class="pt-8 pl-3 pr-3 pb-8">
            <p class="text-h5 main-font-bold main-title-color">{{ $t('dashboard') }}</p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <transition name="slide" mode="out-in">
        <v-col cols="12" v-if="stats" :key="1">
          <v-row>

            <!-- Tickets -->
            <v-col cols="12" md="6">
              <v-card outlined>
                <v-card-title>
                  <v-row>
                    <v-col cols="auto">{{ $t('support_tickets') }}</v-col>
                    <v-col align="right" justify="end">
                      <v-icon large>mdi-lifebuoy</v-icon>
                    </v-col>
                  </v-row>
                </v-card-title>

                <v-card-text class="text-h4">
                  <v-row>
                    <v-col class="warning--text">
                      {{ stats.tickets.pending }}
                      <span class="text-caption warning--text">{{ $t('pending') }}</span>
                    </v-col>
                    <v-col class="success--text">
                      {{ stats.tickets.open }}
                      <span class="text-caption success--text">{{ $t('open') }}</span>
                    </v-col>
                    <v-col class="error--text">
                      {{ stats.tickets.closed }}
                      <span class="text-caption error--text">{{ $t('closed') }}</span>
                    </v-col>
                    <v-col>
                      {{ stats.tickets.comments }}
                      <span class="text-caption">{{ $t('comments') }}</span>
                    </v-col>
                  </v-row>

                </v-card-text>
              </v-card>
            </v-col>


            <v-col cols="12" md="6">
              <!-- Pays -->
              <v-card outlined>
                <v-card-title>
                  <v-row>
                    <v-col cols="auto">{{ $t('pays') }}</v-col>
                    <v-col align="right" justify="end">
                      <v-icon large>mdi-cash-multiple</v-icon>
                    </v-col>
                  </v-row>
                </v-card-title>

                <v-card-text class="text-h4">
                  <v-row>
                    <v-col>
                      <v-card-text class="text-h4">
                        {{ stats.pays.all }}
                        <div class="text-caption">{{ $t('all') }}</div>
                      </v-card-text>
                    </v-col>
                    <v-col>
                      <v-card-text class="text-h4">
                        {{ stats.pays.successful }}
                        <div class="text-caption">{{ $t('successful') }}</div>
                      </v-card-text>
                    </v-col>
                    <v-col>
                      <v-card-text class="text-h4">
                        {{ stats.pays.amount.toFixed(1) }}
                        <div class="text-caption">{{ $t('amount') }}</div>
                      </v-card-text>
                    </v-col>
                  </v-row>

                </v-card-text>
              </v-card>
            </v-col>


          </v-row>
        </v-col>
        <v-col cols="12" v-else :key="0">
          <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </v-col>
      </transition>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    loading: true,
    stats: null,
  }),
  created() {
    this.getStats();
  },
  methods: {
    getStats() {
      this.loading = true;
      this.$store.dispatch("getStats").then((resp) => {
        this.stats = resp;
      });
    },
  },
};
</script>
