<template>
  <v-container
      fluid
      tag="section"
      class="pt-3 px-8 pb-8"
  >
    <!-- Main Section -->
    <v-row>
      <v-col cols="12" class="pt-3 pl-3 pr-3 pb-8">
        <p class="text-h5 main-font-bold main-title-color">{{ $t('my_trusted_title') }}</p>
        <div class="subtitle-1 font-weight-light main-grey">
          {{ $t('my_trusted_desc') }}
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <!-- Data Tabs -->
        <v-row class="mt-1">
          <v-col cols="12">
            <v-row v-if="!usersLoading">
              <v-col cols="12">
                <div v-if="users ? users.length : false">
                  <v-hover v-slot="{hover}">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                        <tr>
                          <th class="text-left">#</th>
                          <th class="text-left">{{ $t('name') }}</th>
                          <th class="text-left">{{ $t('email') }}</th>
                          <th class="text-left">{{ $t('status') }}</th>
                          <th class="text-center">{{ $t('actions') }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(user, i) in users" :key="user.id"
                            :color="(user.status != 'requested' && (hover || (selectedUser ? selectedUser.id == user.id : false))) ? 'primary' : user.status == 'requested' ? 'grey' : 'white'"
                            :class="{'cursor-pointer': user.status != 'requested'}" @click="user.status != 'requested' ? selectedUser = user : null">
                          <td>{{ i + 1 }}</td>
                          <td>{{ user.first_name }} {{ user.last_name }}</td>
                          <td>{{ user.email }}</td>
                          <td>{{ user.status === 'invited' ? $t('trusted_user_invited') : $t('trusted_user_entrusted') }}</td>
                          <td class="text-right">
                            <v-btn v-if="user.status === 'invited'" medium text fab color="primary" @click="selectedUser = user; resendEmailConfirmDialog = true" :loading="deleteUserLoading"
                                   :disabled="deleteUserLoading">
                              <v-icon>mdi-email-outline</v-icon>
                            </v-btn>
                            <v-btn medium text fab color="error" @click="selectedUser = user; deleteConfirmDialog = true" :loading="deleteUserLoading" :disabled="deleteUserLoading">
                              <v-icon>mdi-delete-outline</v-icon>
                            </v-btn>
                          </td>
                        </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-hover>
                </div>
                <v-alert
                    v-else
                    color="primary"
                    class="white--text"
                    elevation="5"
                >
                  <span class="text-center text-sm-left"> {{ $t('no_trusted_users') }}</span>
                </v-alert>
              </v-col>

              <!-- Add New Button -->
              <v-col justify="center" align="center" cols="12" class="mt-10 mb-16">
                <v-btn fab x-large color="primary" @click="openAddTrusterDialog()">
                  <v-icon color="white">mdi-account-plus-outline</v-icon>
                </v-btn>
                <p class="text-subtitle-1 mt-5">{{ $t('add_user') }}</p>
              </v-col>

            </v-row>
            <v-row v-else class="mb-5 mt-5" :key="0">
              <v-col cols="12">
                <v-progress-linear indeterminate color="primary"></v-progress-linear>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <transition name="slide" mode="out-in">
        <v-col cols="12" class="mt-10" v-if="selectedUser">
          <v-row>
            <!-- Trusted User Informations -->
            <v-col cols="9">
              <v-row>
                <v-col cols="12">
                  <div class="text-h6 main-font-bold main-title-color">{{ selectedUser.first_name }} {{ selectedUser.last_name }}</div>
                  <div class="subtitle-1 font-weight-light main-grey mt-0 pt-0">{{ $t('personal_information') }}</div>
                </v-col>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="3">
                      <div class="text-subtitle-1 main-title-color">{{ $t('name') }}</div>
                      <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0">{{ selectedUser.first_name }} {{ selectedUser.last_name }}</div>
                    </v-col>
                    <v-col cols="3">
                      <div class="text-subtitle-1 main-title-color">{{ $t('email') }}</div>
                      <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0">{{ selectedUser.email }}</div>
                    </v-col>
                    <v-col cols="3">
                      <div class="text-subtitle-1 main-title-color">{{ $t('address') }}</div>
                      <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0">{{ selectedUser.street }} {{ selectedUser.house_number }}<br>{{ selectedUser.zip }} {{ selectedUser.city }}</div>
                    </v-col>
                    <v-col cols="3">
                      <div class="text-subtitle-1 main-title-color">{{ $t('phone') }}</div>
                      <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0">{{ selectedUser.phone || '---' }}</div>
                    </v-col>
                    <v-col cols="3">
                      <div class="text-subtitle-1 main-title-color">{{ $t('birthdate') }}</div>
                      <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0">{{ selectedUser.bdate || '---' }}</div>
                    </v-col>
                    <v-col cols="3">
                      <div class="text-subtitle-1 main-title-color">{{ $t('gender') }}</div>
                      <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0">{{ $t(selectedUser.gender == 'other' ? 'other_gender' : selectedUser.gender) }}</div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </transition>
    </v-row>

    <!-- Delete Trusted User Confirm Dialog -->
    <v-dialog
        v-model="deleteConfirmDialog"
        persistent
        max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ $t('are_you_sure') }}
        </v-card-title>

        <v-card-text>
          {{ $t('delete_trusted_alert') }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
              color="error darken-1"
              text
              @click="deleteTrustedUser(selectedUser.id)"
          >
            {{ $t('yes') }}
          </v-btn>

          <v-btn
              color="primary darken-1"
              text
              @click="deleteConfirmDialog = false"
          >
            {{ $t('no') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Resend Truster E-Mail -->
    <v-dialog
        v-model="resendEmailConfirmDialog"
        persistent
        max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ $t('are_you_sure') }}
        </v-card-title>

        <v-card-text>
          {{ $t('resend_email_alert') }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
              color="error darken-1"
              text
              @click="resendEmail()"
          >
            {{ $t('yes') }}
          </v-btn>

          <v-btn
              color="primary darken-1"
              text
              @click="resendEmailConfirmDialog = false;"
          >
            {{ $t('no') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Add Trusted User Dialog -->
    <v-dialog
        v-model="addTrustedUserDialog"
        persistent
        max-width="700"
    >
      <v-card class="pa-4">
        <v-card-title class="text-h5">
          <span v-html="$t('add_user')"></span>
          <v-spacer></v-spacer>
          <v-btn icon @click="addTrustedUserDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-5 text--primary">
          <v-row v-if="!addTrustedUserFormType">
            <v-col cols="12">
              <v-btn
                  block
                  large
                  color="primary"
                  min-height="80"
                  @click="addTrustedUserFormType = 'already_registered'"
              >
                {{ $t('trusted_add_already_registered') }}
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-btn
                  block
                  large
                  color="white"
                  min-height="80"
                  @click="addTrustedUserFormType = 'not_registered'"
              >
                {{ $t('trusted_add_unregistered') }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="addTrustedUserFormType && addTrustedUserFormType === 'already_registered'">
            <v-col cols="12">
              <p>{{ $t('trusted_user_id_place_description') }}</p>
              <v-text-field
                  :label="$t('trusted_user_id')"
                  placeholder="51cdabb6-6fb3-4f8d-839f-eaff23a84f91"
                  v-model="trustedUserId"
                  :loading="addUserLoading"
                  :disabled="addUserLoading"
                  outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-else-if="addTrustedUserFormType && addTrustedUserFormType === 'not_registered'">
            <v-col cols="12" md="6">
              <v-text-field
                  class="pa-0"
                  :label="$t('first_name')"
                  v-model="newUser.first_name"
                  :loading="addUserLoading"
                  :disabled="addUserLoading"
                  outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                  class="pa-0"
                  :label="$t('last_name')"
                  v-model="newUser.last_name"
                  :loading="addUserLoading"
                  :disabled="addUserLoading"
                  outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                  class="pa-0"
                  :label="$t('username_or_email')"
                  v-model="newUser.email"
                  :loading="addUserLoading"
                  :disabled="addUserLoading"
                  outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                  class="pa-0"
                  :label="$t('phone')"
                  v-model="newUser.phone"
                  :loading="addUserLoading"
                  :disabled="addUserLoading"
                  outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                  class="pa-0"
                  :label="$t('street')"
                  v-model="newUser.street"
                  :loading="addUserLoading"
                  :disabled="addUserLoading"
                  outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                  class="pa-0"
                  :label="$t('house_number')"
                  v-model="newUser.house_number"
                  :loading="addUserLoading"
                  :disabled="addUserLoading"
                  outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                  class="pa-0"
                  :label="$t('zip')"
                  v-model="newUser.zip"
                  :loading="addUserLoading"
                  :disabled="addUserLoading"
                  outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                  class="pa-0"
                  :label="$t('city')"
                  v-model="newUser.city"
                  :loading="addUserLoading"
                  :disabled="addUserLoading"
                  outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                  :items="[
                                    { text: $t('male'), value: 'male' },
                                    { text: $t('female'), value: 'female' },
                                    { text: $t('other_gender'), value: 'other' },
                                ]"
                  v-model="newUser.gender"
                  :loading="addUserLoading"
                  :disabled="addUserLoading"
                  :label="$t('gender')"
              ></v-select>
            </v-col>

            <v-col cols="12" md="6">
              <v-menu
                  v-model="addTrustedUserDatePickerMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="formattedNewUserDate"
                      class="pa-0"
                      :label="$t('birthdate')"
                      :loading="addUserLoading"
                      :disabled="addUserLoading"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                  ></v-text-field>
                </template>
                <v-date-picker
                    locale="de"
                    color="primary"
                    v-model="newUser.bdate"
                    :max="maxDate"
                    min="1900-01-01"
                    @input="onAddTrustedUserDatePickerInput"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pt-0" v-if="addTrustedUserFormType">
          <v-spacer></v-spacer>
          <v-btn
              :disabled="!addUserLoading && !addTrustedUserFormValid"
              :loading="addUserLoading"
              color="primary"
              :style="{'width': $vuetify.breakpoint.smAndDown ? '100%' : ''}"
              @click="addTrustedUser"
          >
            {{ $t('add_to_trusted') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <NoSubscriptionDialog/>
  </v-container>
</template>

<script>
import NoSubscriptionDialog from "@/widgets/NoSubscriptionDialog.vue";
import NoFilesUploaded from "@/widgets/NoFilesUploaded.vue";

export default {
  components: {NoFilesUploaded, NoSubscriptionDialog},
  data() {
    return {
      usersLoading: true,
      deleteConfirmDialog: false,
      resendEmailConfirmDialog: false,
      addTrustedUserDialog: false,
      trustedUserId: null,
      addTrustedUserDatePickerMenu: false,
      addTrustedUserFormType: null,
      users: null,
      entries: [],
      isLoading: false,
      addUserLoading: false,
      newUser: {},
      items: [],
      deleteUserLoading: false,
      selectedUser: null,
      maxDate: new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)
          .toISOString().substring(0, 10), // Max date: today
    };
  },
  computed: {
    addTrustedUserFormValid() {
      if (!this.addTrustedUserFormType) {
        return false;
      }

      if (this.addTrustedUserFormType === 'already_registered') {
        return !!this.trustedUserId;
      } else {
        return this.newUser.first_name &&
            this.newUser.last_name &&
            this.newUser.email &&
            this.newUser.phone &&
            this.newUser.street &&
            this.newUser.house_number &&
            this.newUser.zip &&
            this.newUser.city &&
            this.newUser.gender &&
            this.newUser.bdate;
      }
    },
    formattedNewUserDate() {
      if (!this.newUser.bdate) {
        return '';
      }

      const date = new Date(this.newUser.bdate);

      return `${('0' + date.getDate()).slice(-2)}.${('0' + (date.getMonth() + 1)).slice(-2)}.${date.getFullYear()}`;
    },
  },
  mounted() {
    this.getTrustedUsers()
  },
  watch: {
    addTrustedUserDialog(val) {
      if (!val) {
        setTimeout(() => {
          this.resetAddTrustedUserForm()
        }, 500)
      }
    }
  },
  methods: {
    getTrustedUsers() {
      this.usersLoading = true
      this.$store.dispatch('getTrustedUsers')
          .then(resp => {
            this.users = resp
            this.usersLoading = false
          })
    },
    openAddTrusterDialog() {
      this.trustedUserId = null
      this.newUser = {}
      this.addTrustedUserDialog = true
    },
    addTrustedUser() {
      let postData

      if (this.addTrustedUserFormType === 'already_registered') {
        postData = {addType: this.addTrustedUserFormType, trustedUserID: this.trustedUserId}
      } else {
        if (!this.newUser.first_name ||
            !this.newUser.last_name ||
            !this.newUser.email ||
            !this.newUser.phone ||
            !this.newUser.street ||
            !this.newUser.house_number ||
            !this.newUser.zip ||
            !this.newUser.city ||
            !this.newUser.gender ||
            !this.newUser.bdate)
          return

        this.newUser.addType = this.addTrustedUserFormType

        postData = this.newUser
      }

      this.addUserLoading = true

      this.$store.dispatch('addTrustedUser', postData)
          .then(() => {
            this.addUserLoading = false
            this.newUser = {}

            this.$helpers.showMessage('success', this.$t('trusted_added_success'))
            this.getTrustedUsers()
            this.addTrustedUserDialog = false
          })
          .catch(err => {
            this.addUserLoading = false
            this.$helpers.showError(err)

            if (err.response.status === 422) {
              this.resetAddTrustedUserForm();
              this.addTrustedUserFormType = 'already_registered';
            }
          })
    },
    resetAddTrustedUserForm() {
      this.addTrustedUserFormType = null;
      this.newUser = {};
    },
    onAddTrustedUserDatePickerInput(value) {
      this.addTrustedUserDatePickerMenu = false;
      this.newUser.bdate = value;
    },
    resendEmail() {
      if (this.selectedUser) {
        this.deleteUserLoading = false
        console.log(this.selectedUser)
        this.$store.dispatch('resendEmailForTruster', this.selectedUser.id)
            .then(() => {
              this.deleteUserLoading = false
              this.resendEmailConfirmDialog = false
              this.selectedUser = null
              this.$helpers.showMessage('success', this.$t('truster_email_resent'))
              this.getTrustedUsers()
            })
            .catch(err => {
              this.deleteUserLoading = false
              this.$helpers.showError(err)
            })
      } else {
        this.resendEmailConfirmDialog = true
      }
    },
    deleteTrustedUser(id) {
      if (this.selectedUser) {
        this.deleteUserLoading = true
        this.$store.dispatch('deleteTrustedUser', this.selectedUser.id)
            .then(() => {
              this.deleteUserLoading = false
              this.deleteConfirmDialog = false
              this.selectedUser = null
              this.$helpers.showMessage('success', this.$t('trusted_removed_success'))
              this.getTrustedUsers()
            })
            .catch(err => {
              this.deleteUserLoading = false
              this.$helpers.showError(err)
            })
      } else {
        this.selectedUser = id
        this.deleteConfirmDialog = true
      }
    },
  }
};
</script>
