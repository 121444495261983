<template>
  <v-container fluid tag="section">

    <!-- Head Buttons -->
    <v-row align="center" justify="center">
      <v-col align="center" justify="center" align-self="center">
        <v-select
            hide-details
            :items="[{text: 'Cat A', value:'0'}, {text: 'Cat B', value:'1'}]"
            label="Outlined style"
            outlined
        ></v-select>
      </v-col>
      <v-col align="right" justify="end" align-self="center">
        <router-link :to="{ name: 'newDocument' }">
          <v-btn large color="primary">
            <v-icon class="mr-2">mdi-plus</v-icon>
            New File
          </v-btn>
        </router-link>
      </v-col>
    </v-row>

    <!-- Documents -->
    <transition name="slide" mode="out-in">
      <v-row v-if="documents && !loading" class="mt-10" :key="1">
        <v-col v-for="document in documents" :key="document.id" cols="4" class="pa-4">
          <v-row class="media-card">
            <v-col cols="12">

            </v-col>

            <v-col cols="12" class="media-item">
              <h1>{{ media.name }}</h1>
            </v-col>

            <v-col cols="12">
              <v-row>
                <v-col>
                  <v-btn
                      class="mx-2"
                      fab
                      small
                      color="error"
                      @click="deleteDocument(media.id)"
                      :loading="fabLoader == media.id"
                      :disabled="fabLoader == media.id"
                  >
                    <v-icon dark>
                      mdi-delete-outline
                    </v-icon>
                  </v-btn>

                  <v-btn
                      class="mx-2"
                      fab
                      small
                      color="success"
                      :loading="fabLoader == media.id"
                      :disabled="fabLoader == media.id"
                  >
                    <v-icon dark>
                      mdi-lock
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-if="!documents.length" justify="center" align="center" cols="12">
          <v-icon x-large color="warning">mdi-alert-rhombus-outline</v-icon>
          <h1 class="mt-5">No file of this type has been uploaded!</h1>
        </v-col>
      </v-row>
      <v-row v-else class="mb-5" :key="0">
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </v-row>
    </transition>


    <!-- Delete Document Confirm Dialog -->
    <v-dialog
        v-model="deleteConformDialog"
        max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          Are your sure?
        </v-card-title>

        <v-card-text>
          Deleting this file will no longer be available.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
              color="error darken-1"
              text
              @click="deleteDocument(selectedDocument)"
          >
            Yes
          </v-btn>

          <v-btn
              color="primary darken-1"
              text
              @click="deleteConformDialog = false; selectedDocument = null;"
          >
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      documents: null,
      fabLoader: false,
      deleteConformDialog: false,
      selectedDocument: null,
    }
  },
  watch: {
    '$route.name'() {
      this.getDocuments()
    }
  },
  mounted() {
    this.getDocuments()
  },
  methods: {
    getDocuments() {
      this.loading = true
      this.$store.dispatch('getDocuments', this.$route.params.collection)
          .then(resp => {
            this.documents = resp
            this.loading = false
          })
          .catch(err => {
            this.loading = false
            this.$helpers.showError(err)
          })
    },
    deleteDocument(id) {
      if (!this.selectedDocument) {
        this.selectedDocument = id
        this.deleteConformDialog = true
      } else {
        this.fabLoader = id
        this.deleteConformDialog = false
        this.$store.dispatch('deleteDocument', id)
            .then(() => {
              this.fabLoader = false
              this.getDocuments()
            })
            .catch(() => {
              this.fabLoader = false
            })
      }
    },
  }
}
</script>
