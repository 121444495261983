import Vue from 'vue'
import VueRouter from 'vue-router';
import VueRouteMiddleware from 'vue-route-middleware';

import routes from "./routes";
import lang from "@/plugins/i18n";
import store from "../../store";

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        return {x: 0, y: 0};
    }
});

router.beforeEach(VueRouteMiddleware());

let allowedRoutesForTrustedUser = [
    'collections',
    'personaldocsIndex',
    'advisorIndex',
    'lastwishIndex',
    'tresor'
];

let allowedRoutesForAdmin = [
    'advisorIndex'
];

router.beforeEach((to, from, next) => {
    // first check allowed dashboard routes for trusted users and admins
    let allowed = true

    if(typeof to.matched[1] !== 'undefined' && to.matched[1].name === 'dashboard') {
        let routeIndexName = to.matched[2].name ?? ''

        if(store.getters.userIsLoggedInAsTrustedUser && !allowedRoutesForTrustedUser.includes(routeIndexName)
            || (store.getters.adminIsLoggedInAsUser && store.getters.adminRole === 'alleato Admin' && !allowedRoutesForAdmin.includes(routeIndexName)))
            allowed = false
    }

    if(allowed) {
        // set meta tags
        if (to.meta && to.meta.title) {
            document.title = to.meta.title;
        } else {
            document.title = lang.t('meta_tags')['home'].title;
        }

        const metaDescription = document.querySelector('meta[name="description"]');

        if (metaDescription && to.meta.description) {
            metaDescription.setAttribute('content', to.meta.description);
        } else if (metaDescription) {
            metaDescription.setAttribute('content', lang.t('meta_tags')['home'].description);
        }

        next();
    } else {
        next({ path: from.path})
    }
});

export default router
