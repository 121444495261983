import Vue from 'vue'
import App from './Apps/Public.vue'
import Axios from 'axios'
import router from './router/public/index';
import store from './store/index'

import VueProgressBar from 'vue-progressbar'
import i18n from './plugins/i18n';
import VueElementLoading from 'vue-element-loading'
import VueToast from 'vue-toast-notification';
import vuetify from './plugins/vuetify';
import VueMeta from 'vue-meta'
import helpers from './helpers/helpers'
import VueAuthImage from 'vue-auth-image';
import VueAuthHref from 'vue-auth-href'
import {TiptapVuetifyPlugin} from 'tiptap-vuetify'

import jsrsasign from 'jsrsasign'
import jsrsasignUtil from 'jsrsasign-util'
import JsEncrypt from 'jsencrypt/bin/jsencrypt';
import md5 from 'js-md5';
import VueHtml2pdf from 'vue-html2pdf'
import VueVideoPlayer from 'vue-video-player'

// require videojs style
import 'video.js/dist/video-js.css'
import './plugins/base'

import 'vue-toast-notification/dist/theme-sugar.css';
import 'tiptap-vuetify/dist/main.css'
import $ from 'jquery'

let base64 = require('js-base64').Base64;

// import 'vue-video-player/src/custom-theme.css'

Vue.use(VueVideoPlayer, /* {
  options: global default options,
  events: global videojs events
} */)

Vue.config.productionTip = false

window.$ = $

window.Vue = Vue;

Vue.prototype.RSA = jsrsasign;
Vue.prototype.RSAU = jsrsasignUtil;

Vue.prototype.$rsa = JsEncrypt;
Vue.prototype.$md5 = md5;
Vue.prototype.$base64 = base64;

Vue.prototype.$http = Axios;
// Axios.defaults.withCredentials = true
Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
Axios.defaults.baseURL = store.getters.apiUrl
const token = localStorage.getItem('token')
if (token) {
    Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

const authOptions = {
    Authorization: () => 'Bearer ' + token,
    token: () => token,
}

const plugin = {
    install() {
        Vue.helpers = helpers
        Vue.prototype.$helpers = helpers
    }
}
Vue.use(plugin)
Vue.use(TiptapVuetifyPlugin, {
    vuetify,
    iconsGroup: 'md'
})
Vue.use(VueAuthImage)
Vue.use(VueAuthHref, authOptions)
Vue.use(VueMeta)
Vue.use(VueToast, {
    position: 'bottom'
})
Vue.use(VueHtml2pdf)

Vue.component('app', require('./Apps/Public.vue'));
Vue.component('VueElementLoading', VueElementLoading)

// Progress bar Options
const options = {
    color: '#ceb561',
    failedColor: '#d32f2f',
    thickness: '5px',
    transition: {
        speed: '0.2s',
        opacity: '0.6s',
        termination: 300
    },
    autoRevert: true,
    location: 'top',
    inverse: false
}

Vue.use(VueProgressBar, options)

const app = new Vue({
    render: h => h(App),
    vuetify,
    i18n,
    router,
    store,
    created() {
        this.$Progress.start()

        this.$router.beforeEach((to, from, next) => {
            if (to.meta.progress !== undefined) {
                let meta = to.meta.progress
                this.$Progress.parseMeta(meta)
            }
            this.$Progress.start()
            next()
        })

        this.$router.afterEach((to, from) => {
            this.$Progress.finish()
        })
    },
    mounted() {
        this.$Progress.finish()
    }
}).$mount('#app');
