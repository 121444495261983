<template>
    <v-app v-if="!$route.meta.authOnly" style="margin-top: 90px">
      <app-navigation></app-navigation>
      <router-view />

      <vue-progress-bar></vue-progress-bar>
    </v-app>
    <section v-else>
      <transition name="slide" mode="out-in">
        <router-view />
      </transition>
    </section>
</template>

<script>
import Navigation from '../components/public/Navigation.vue'
import Footer from '../components/public/Footer.vue'
export default {
    data(){
        return {
            langs: ['en', 'de', 'jp', 'tr'],
            loading: false
        }
    },
    components: {
        appNavigation: Navigation,
        appFooter: Footer
    },
    mounted(){
        this.$store.dispatch('getSettings')

        
        if (this.$route.name != 'login')
            this.$store.dispatch('getMe')
            .catch(err => {
                if (this.$store.getters.isLoggedIn){
                    this.$helpers.showMessage('error', this.$t('token_expired'))
                    this.$store.commit('logout')
                    localStorage.removeItem('token')
                    delete this.axios.defaults.headers.common['Authorization']
                    this.$router.go({name: 'adminLogin'})
                }
            })
    },
}
</script>